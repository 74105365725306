let data = [];

$(document).ready(function () {

	var endpoint = "/list";
	var username = 'bob';
	var password = 'TheBuilder';

	$.ajaxSetup({
		crossDomain: true,
		xhrFields: {
			withCredentials: true
		}
	});

	var xhr1 = $.ajax({
		url: endpoint,
		type: 'GET',
		data: data,
		dataType: "json",
		headers: {
			"Authorization": "Basic " + btoa(username + ":" + password)
		}
	});

	var xhr2 = $.ajax({
		dataType: "json",
		url: 'cookies.json',
		data: data
	});

	$.when(xhr1, xhr2).done(function (resp1, resp2) {

		let cookieData = resp2;
		let projectBlacklist = getBlacklist(JSON.parse(JSON.stringify(resp1[0])));

		let data = resp1[0].filter(function (el) {
			return !(_.indexOf(projectBlacklist, el.Name) > -1);
		});

		// console.log(cookieData)

		buildProjectList(data, cookieData);
		buildProjectScroll(data);
		buildBadges(data);

		$('.project-index').scrollToAnchors();

		$('document').spinner('hide');

	});

});

let getBlacklist = function (data) {

	let projectSites = data.filter(function (el) {
		return el.Name === "sites";
	})[0];

	return projectSites.Releases[0].blacklist;
};

let buildProjectList = function (data, cookieData) {

	data.forEach(function (element, index) {

		if (element.Environments !== null) {
			['testing', 'staging', 'production'].forEach(function (envName) {
				let env = element.Environments[envName];
				let url = env.url.substr(8).replace(/\//g, '_').replace(/\./g, '-');
				env.img = url + ".png";
				env.date = timeConverter(env.built_at);
			})

			// merge the cookie data into api list data -- start

			let url = element.Environments.production.url;

			let searchString = getDomainName(url)
			let candidates = Object.keys(cookieData[0])

			candidates.forEach(key => {

				if (searchString === getDomainName(key)) {
					element.Cookies = cookieData[0][key].cookies
				}

			})

		} else {
			data.splice(index, 1);
		}

	});

	buildFromTemplate('#tpl-list', '.project-list', data);

};

let buildProjectScroll = function (data) {

	buildFromTemplate('#tpl-index', '.project-index .horizontal-scroll-wrapper', data);

}

let buildBadges = function (data) {

	data.forEach(function (element, index) {

		let id = "#" + element.Name;

		if (element.Releases !== null) {

			if (element.Releases[0].description.indexOf("blankpage") === 0) {
				attachBadge($(id + ' .project-top-section-badge'), "is-blankpage");
			} else if (element.Releases[0].description.indexOf("redirect") === 0) {
				attachBadge($(id + ' .project-top-section-badge'), "is-redirect");
			} else if (element.Releases[0].description.indexOf("under construction") === 0) {
				attachBadge($(id + ' .project-top-section-badge'), "is-under-construction");
			} else {
				attachBadge($(id + ' .project-top-section-badge'), "is-live");

			}
		}

		if (element.Cookies !== null && element.Cookies !== undefined) {

			// console.log(element.Cookies)

			attachBadge($(id + ' .project-top-section-badge'), "is-with-cookies");
		}

	})
}

let buildFromTemplate = function (templ, target, data) {
	
	console.log(data.Environments)

	let tpl = _.template($(templ).html());

	$(target).html(tpl({
		projects: data
	}));

	$('.body-content').linkTap();
}

function timeConverter(UNIX_timestamp) {
	var a = new Date(UNIX_timestamp * 1000);
	var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var sec = a.getSeconds();

	if (min < 10) {
		min = '0' + min;
	}

	var time = date + ' ' + month + ' ' + year + ' - ' + hour + ':' + min;
	return time;
}

function getDomainName(target) {

	let matches = target.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
	let domainName = matches && matches[1]

	return domainName

}

function attachBadge(target, string) {
	// console.log(target);
	var span = document.createElement('span');
	// span.innerHTML = string;
	span.setAttribute('class', string); // and make sure myclass has some styles in css
	target.after(span);

}